/* Estilo base para os botões */
.button {
    background-color: #007bff;
    /* Azul padrão */
    border: none;
    color: white;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    /* Cantos arredondados */
    transition: background-color 0.3s, transform 0.2s;
    /* Transições suaves */
}

/* Estilo para o botão Editar */
.button-edit {
    background-color: #28a745;
    /* Verde */
}

.button-edit:hover {
    background-color: #218838;
    /* Verde escuro */
    transform: scale(1.05);
    /* Leve aumento no hover */
}

/* Estilo para o botão Deletar */
.button-delete {
    background-color: #dc3545;
    /* Vermelho */
}

.button-delete:hover {
    background-color: #c82333;
    /* Vermelho escuro */
    transform: scale(1.05);
    /* Leve aumento no hover */
}

/* Estilo para o botão Enviar */
.button-send {
    background-color: #ffc107;
    /* Amarelo */
}

.button-send:hover {
    background-color: #e0a800;
    /* Amarelo escuro */
    transform: scale(1.05);
    /* Leve aumento no hover */
}


/* Estilos de eventos */
.invalid-event {
    background-color: rgba(150, 0, 0, 0.5);
    color: white;
    box-shadow: inset 8px 0 0 red;
}

.sended-event {
    background-color: rgba(0, 150, 0, 0.37);
    color: white;
    box-shadow: inset 8px 0 0 green;
}

.inactive-event {
    background-color: rgba(150, 150, 0, 0.6);
    color: white;
    box-shadow: inset 8px 0 0 orange;
}

.invalid-event-aprovated {
    color: #000000;
    box-shadow: inset 8px 0 0 red;
}

.sended-event-aprovated {
    color: #000000;
    box-shadow: inset 8px 0 0 green;
}

.inactive-event-aprovated {
    color: #000000;
    box-shadow: inset 8px 0 0 orange;
}

.tableHead {
    color: #000000;
    box-shadow: inset 0 0 0 8px #173f74;
}
