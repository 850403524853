.scheduleGrid {
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}

.calendar {
  width: 100%;
  max-width: 1600px; /* Defina um limite máximo para não ficar exagerado */
  min-height: 70vh;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.weekday {
  background-color: #173f74;
  color: white;
  padding: 10px 0;
  font-weight: bold;
  text-align: center;
}

.days-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  width: 100%;
  max-width: 1600px;
  flex-grow: 1;
}

.day {
  padding: 20px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: 0.3s;
  cursor: pointer;
  background: white;
  position: relative;
}

.day:hover {
  background: #f0f8ff;
}

.disabled {
  color: #ccc;
  background: #f9f9f9;
}

.event {
  color: black;
  padding: 5px;
  font-size: 12px;
  border-radius: 5px;
  margin-top: 5px;
  text-align: center;
}

/* Botões flutuantes */
.nav-buttons {
  position: fixed;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  pointer-events: none; /* Permite que cliques passem por este elemento */
}

.nav-buttons button {
  pointer-events: auto; /* Garante que os botões dentro do elemento ainda sejam clicáveis */
  background: #173f74;
  color: white;
  border: none;
  padding: 15px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  margin: 20px;
}

.nav-buttons button:hover {
  background: #0056b3;
}

/* Responsividade */
@media (max-width: 1024px) {
  .calendar {
      width: 95vw;
  }

  .days-grid {
      grid-template-columns: repeat(7, minmax(40px, 1fr));
      gap: 4px;
  }

  .day {
      padding: 15px;
      font-size: 14px;
  }
}

@media (max-width: 768px) {
  .calendar {
      width: 95vw;
  }

  .days-grid {
      grid-template-columns: repeat(7, minmax(30px, 1fr));
      gap: 3px;
  }

  .day {
      padding: 10px;
      font-size: 12px;
  }
}

@media (max-width: 480px) {
  .days-grid {
      grid-template-columns: repeat(7, minmax(25px, 1fr));
      gap: 2px;
  }

  .day {
      padding: 8px;
      font-size: 10px;
  }

  /* Oculta o texto dos eventos em telas pequenas */
  .event {
      font-size: 0;
      line-height: 0;
      color: transparent;
  }

  .event::before {
      content: "●";
      font-size: 14px;
      color: #173f74;
  }

  .sended-event{
    background-color: green;
  }
  .inactive-event{
    background-color: orange;
  }
  .invalid-event{
    background-color: red;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
}

.modal-content {
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  width: auto;
  max-width: 90vw; 
  height: auto; /* Ajusta a altura ao conteúdo */
  max-height: 80vh; /* Nunca ultrapassa 80% da altura da tela */
  overflow-y: auto; /* Adiciona rolagem interna se necessário */
  display: flex;
  flex-direction: column;
}


.modal-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.modal-body {
  flex-grow: 1; /* Faz com que o conteúdo se ajuste ao tamanho disponível */
  overflow-y: auto; /* Impede que o conteúdo vaze */
  max-height: 60vh; /* Ajusta a altura máxima do conteúdo */
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.modal-close {
  margin-top: 20px;
  padding: 10px 20px;
  width: 100%;
  border-radius: 30px;
  background-color: #d9534f;
  color: #222;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.modal-close:hover {
  background-color: #c9302c;
}

@media (max-width: 480px) {
  .modal-content {
    width: 95vw;
    max-height: 85vh;
    padding: 15px;
  }
}
