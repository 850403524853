/* form.css */

/* Estilo do Overlay do Modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    padding: 20px;
}

/* Estilo do Conteúdo do Modal */
.modal-content {
    background: #ffffff;
    border-radius: 8px;
    width: 100%;
    max-width: 600px;
    padding: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    max-height: 80vh; /* Para garantir que o modal não ultrapasse a tela */
    overflow-y: auto; /* Adiciona rolagem se necessário */
}

/* Estilização do scroll no Chrome, Edge e Safari */
.modal-content::-webkit-scrollbar {
    width: 8px; /* Largura da barra */
}

.modal-content::-webkit-scrollbar-thumb {
    background-color: #173f74; /* Cor da barra de rolagem */
    border-radius: 4px; /* Bordas arredondadas */
}

.modal-content::-webkit-scrollbar-track {
    background: #f0f0f0; /* Cor do fundo do scroll */
}

/* Estilização do scroll no Firefox */
.modal-content {
    scrollbar-width: thin; /* Deixa a barra de rolagem mais fina */
    scrollbar-color: #173f74 #f0f0f0; /* Cor do thumb e do track */
}


/* Estilo dos botões primário e secundário */
.btn {
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s ease;
}

.btn-primary {
    background-color: #007bff;
    color: #fff;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.btn-secondary {
    background-color: #6c757d;
    color: #fff;
}

.btn-secondary:hover {
    background-color: #5a6268;
}


/* Estilo do Formulário */
.event-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* Título do Formulário */
.event-form h2 {
    margin-bottom: 20px;
    font-size: 1.8rem;
    color: #333333;
    text-align: center;
}

/* Grupos de Campos */
.form-group-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}

/* Grupos de Campos */
.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 100%;
}

/* Labels */
.form-group label {
    margin-bottom: 5px;
    font-size: 0.95rem;
    color: #555555;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Indicação de Campo Obrigatório */
.required {
    color: #e74c3c;
    margin-left: 5px;
}

/* Inputs e Selects */
.form-group input,
.form-group select,
.form-group textarea {
    padding: 10px 12px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 1rem;
    color: #333333;
    background-color: #f9f9f9;
    transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
    border-color: #61dafb;
    outline: none;
    background-color: #ffffff;
}

/* Textarea */
.form-group textarea {
    resize: vertical;
    min-height: 80px;
    max-height: 200px;
}

/* Botão para Alternar Dia Final */
.toggle-end-date {
    background: none;
    border: none;
    color: #61dafb;
    cursor: pointer;
    font-size: 0.95rem;
    align-self: flex-start;
    margin-bottom: 15px;
    padding: 0;
}

.toggle-end-date:hover {
    text-decoration: underline;
}

/* Mensagem de Erro */
.error-message {
    color: #e74c3c;
    font-size: 0.9rem;
    margin-bottom: 15px;
    text-align: center;
}

/* Ações do Formulário */
.form-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

/* Botões */
.save-button,
.cancel-button {
    flex: 1;
    padding: 12px 0;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.save-button {
    background-color: #61dafb;
    color: #ffffff;
    margin-right: 10px;
}

.save-button:hover {
    background-color: #52c7ea;
}

.cancel-button {
    background-color: #dddddd;
    color: #333333;
}

.cancel-button:hover {
    background-color: #cccccc;
}

.titleInput{
    padding: 10px 12px;
    border: none;
    border-radius: 4px;
    font-size: 2rem;
    text-align: center;
    color: #333333;
    transition: border-color 0.3s ease;
    margin: 2vh;
}


/* Responsividade */
@media (max-width: 600px) {
    .modal-content {
        padding: 20px;
    }

    .form-actions {
        flex-direction: column;
    }

    .save-button,
    .cancel-button {
        margin-right: 0;
        margin-bottom: 10px;
    }
}
